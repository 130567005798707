const RU = {
  moduleCardLessons: "Уроки",
  moduleCardIntro: "Введение",
  moduleCardDiploma: "Сертификат",
  moduleCardFinal: "Финальная страница",
  moduleCardDeadline: "Доступ до {{deadline}}",
  moduleCardDeadlineInformer: "Доступ закончится {{deadline}}",
  moduleCardDeadlinePrice:
    "Вы можете продлить доступ на два месяца за {{price}} руб",
  moduleCardDeadlineHint: "Вы можете продлить доступ еще на два месяца",
  moduleCardCountdown: "Осталось {{days}}",
  moduleCardDoneTasks: "Вы решили {{doneTasks}} из {{total}}",
  moduleCardStatusActive: "Уже решаете",
  moduleCardStatusDeadline: "Дедлайн близко!",
  moduleCardStatusPast: "Доступ закончился {{deadline}}",
  moduleCardPaidDescription:
    "Вы купили этот модуль и получите доступ к задачнику в дату старта. Если что-то пойдет не так, напишите нам в",
  moduleCardProgram: "Описание и программа модуля на сайте",
  moduleCardProgressActive: "пройдено",
  moduleCardProgressDeadline1: "Доступ закончится через",
  moduleCardProgressDeadline2: "{{days}}",
  moduleCardPastProgress: "Вы прошли {{progress}}% модуля",
  moduleCardBuy: "Доступ на два месяца",
  moduleCardGift: "Вы можете купить этот модуль для друга",
  moduleCardBuyButton: "Купить",
  moduleCardBuyGiftButton: "Купить и подарить",
  moduleCardGiftButton: "Подарить",
  moduleCardProlongationButton: "Продлить",
  moduleCardPastBuyButton: "Купить еще раз",
  moduleCardPastGiftButton: "Купить в подарок",
  moduleCardGoButton: "В начало модуля",
  dashboardHint1:
    "Чтобы изменить имя, фамилию, адрес электронной почты или пароль для входа, напишите нам в ",
  dashboardHint2: " или на ",
  botName: "телеграм-бота",
  dashboardAdminHint: "У вас есть права администратора. ",
  dashboardAdminLink: "Перейти в Кафедру",
  helpButton: "Помощь",
  helpButtonInfo: "Уголок студента",
  helpButtonTelegram: "Телеграм-бот",
  dashboardNoActiveModules: "Активных модулей нет. Но можно купить",
  dashboardSeriesUX: "Серия «Аналитический UX»",
  dashboardOtherModules: "Отдельные модули",
  moduleStartHeader: "Добро пожаловать!",
  moduleStartNavHeader: "В этом задачнике",
  moduleStartGo: "Начать 1 урок",
  navMenuIntro: "Введение в модуль",
  navMenuInfo: "Программа модуля на сайте",
  navMenuFinal: "Конец модуля",
  navMenuLesson: "Урок",
  navMenuTheory: "Теория",
  navMenuPractice: "Задача",
  navMenuCounter: "Задача {{current}} из {{total}}",
  navMenuBadgeNew: "Не решена",
  navMenuBadgeInProgress: "Решаю",
  navMenuLessonStart: "Начало урока",
  navMenuLessonFinal: "Конец урока",
  navMenuLessonFinalTitle: "Теперь вы точно можете...",
  lessonNavHeader: "В этом уроке",
  lessonFinalTitle: "Урок окончен!",
  lessonFinalProgress:
    "{{done}} из {{totalTasks}} задач • {{score}} из {{maxScore}} баллов",
  lessonFinalNext: "Перейти к следующему уроку",
  lessonFinalDone: "Закончить модуль",
  moduleFinalHeader: "Ура! Поздравляем!",
  moduleFinalHext: "Что дальше?",
  taskStart: "Читать теорию",
  theoryContinue: "Читать продолжение",
  theoryTaskNext: "Решать задачи",
  taskFinalButton: "Закончить урок",
  taskHint: "Подсказка",
  taskOurVariant: "Наш вариант",
  taskAnalys: "Разбор",
  taskCheck: "Проверить",
  taskCheckHint: "Задачу можно решать любое количество раз",
  taskOneMoreTime: "Решить еще раз",
  taskPrev: "Предыдущая задача",
  taskNext: "Следующая задача",
  taskScore: "Вы набрали \n{{score}} баллов из {{maxScore}}",
  taskInputPlaceholder: "Напишите что-нибудь",
  taskLinkPlaceholder: "Вставьте сюда ссылку",
  taskSelectorPlaceholder: "Выберите вариант",
  taskDefaultOurVar: "Смотрите наш вариант ответа в разборе",
  commentsHeader: "Моё мнение",
  commentsDescription:
    "Сообщайте об ошибках, не соглашайтесь с нами, оставляйте пометки для себя",
  commentsProtest: "Считаю, что мои рассуждения более верные",
  commentProtestCheck:
    "Последнее слово за вами! Вы получаете максимум баллов за эту задачу.",
  saveButton: "Сохранить",
  editButton: "Изменить",
  footerCopyright: "1-я пол. XXI в., дизайн-задачник",
  footerCredentials: "Лицензионный договор — Оферта",
  loginHeader: "Войти и решать",
  loginEmailLabel: "Логин",
  loginPasswordLabel: "Пароль",
  loginButton: "Войти",
  logoutButton: "Выйти",
  loginHint1: "Если вы забыли пароль — напишите нам на почту ",
  loginHint2: " или в ",
  createPasswordTitle: "Придумайте пароль",
  createPasswordLabel: "Новый пароль",
  createPasswordPlaceholder: "Сложный, но чтобы запомнить...",
  createPasswordButton: "Сохранить и войти",
  createPasswordBack: "...или войти с текущим паролем",
  sobakaName: "Собака Павлова",
  popupClose: "Закрыть",
  diplomaUIlang: "Язык",
  diplomaUIstyle: "Оформление",
  diplomaUIformat: "Формат",
  diplomaUIprivacy: "Конфиденциальность",
  diplomaDetail: "Подробно по навыкам",
  diplomaSaveButton: "Скачать в",
  diplomaSaveButtonHint: "С детализацией по навыкам",
  diplomaLinkButton: "Копировать ссылку на сертификат",
  counselorHeader: "Уголок студента",
  counselorBack: "К списку модулей",
  titleLoading: "Загрузка...",
  lessonFinalSummary3:
    "Набранных баллов недостаточно для получения сертификата. Если он вам нужен, дорешайте и перерешайте задачи урока.",
  lessonFinalSummary4:
    "Набранных баллов достаточно для обычного сертификата. Если вы хотите сертификат с отличием, перерешайте некоторые задачи и наберите больше баллов.",
  lessonFinalSummary5:
    "Набранных баллов достаточно для получения сертификата с отличием.",
  welcomePage: "Добро пожаловать!",
  ruLang: "Русский",
  enLang: "Английский",
  isColorControl: "Сделать цветным",
  isMascotControl: "Убрать маскота",
  isProgressControl: "Скрыть результаты",
  privateSecurity: "Вижу только я",
  publicSecurity: "Доступен по внешней ссылке",
};

export { RU };
